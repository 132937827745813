var phunkCookieReady = function (callback) {
	if (document.readyState != "loading"){
		callback();
	}
	else {
		document.addEventListener("DOMContentLoaded", callback);
	}
}

class PhunkCookie {
	constructor() {
		this.init = true;
		this.modal = null;
		this.dummy = document.createElement("Dummy");
		this.dummy.obj= this;

		this.dummy.addEventListener("PhunkCookieInit", function(evt) {
			var obj = evt.target.obj;
			obj.toggleYoutube(evt);
		});

		var that = this;
		document.querySelectorAll('a.phunk-cookie-link').forEach( function(el, index) {
			el.addEventListener("click", function(event) {
				event.preventDefault();
				that.loadModal();
			});
		});

		console.log('constructed');
		document.querySelectorAll('li.phunk-cookie-link > a').forEach( function(el, index) {
			console.log("found 1");
			el.addEventListener("click", function(event) {
				event.preventDefault();
				that.loadModal();
			});
		});
		
		document.querySelectorAll('.phunk-cookie-youtube').forEach( function(el) {
			that.bindYoutubeSingleAction(el);
		});
	}

	toggleYoutube(evt) {
		var that = this;
		console.log("toggleYoutube");
		document.querySelectorAll('.phunk-cookie-youtube').forEach( function(el) {
			that.loadYoutube(el);
		});
	}

	loadModal() {
		url = phunk_cookie_plugin_ajax.ajax_url;
		var that = this;
		var formData = new FormData();
		formData.append("action", "phunk_cookie_plugin_do_ajax_request");
		formData.append("action2", "get_modal");
		fetch(url, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
			},
			body: formData
		})
		.then( function(res) {
			return res.text();
		})
		.then( function(modalText) {
			var range = document.createRange();
			// Make the parent of the first div in the document become the context node
			range.selectNode(document.querySelector('body'));
			var modalFragment = range.createContextualFragment(modalText);
			document.body.appendChild(modalFragment);

			var modal = document.querySelector('.phunk-cookie-wrapper');

			//console.log(modal);

			modal.querySelector('form').addEventListener("submit", function(event) {
				//console.log("submitting");
				event.preventDefault();
				that.submitModal(event.target);
			});
			
			let btnSubmitAll = modal.querySelector('a.phunk-cookie-submit-all');
			if(btnSubmitAll) {
				btnSubmitAll.addEventListener("click", function(event) {
					event.preventDefault();
					modal.querySelector('form').querySelectorAll('input[type=checkbox]').forEach( function(el, index) {
						el.checked = true;
					});
					that.submitModal(modal.querySelector('form'));
				});
			}

			let btnSubmitNone = modal.querySelector('a.phunk-cookie-submit-none');
			if(btnSubmitNone) {
				btnSubmitNone.addEventListener("click", function(event) {
					event.preventDefault();
					modal.querySelector('form').querySelectorAll('input[type=checkbox]').forEach( function(el, index) {
						if(!el.disabled) {
							el.checked = false;
						}
					});
					that.submitModal(modal.querySelector('form'));
				});
			}

			let btnsCollapsibleControl = modal.querySelectorAll('a.collapsible-control');
			if(btnsCollapsibleControl.length) {
				btnsCollapsibleControl.forEach( function(el, index) {
					el.addEventListener('click', function(event) {
						event.preventDefault();
						el.closest('.collapsible').classList.toggle('active');
					});
				});
			}

			document.querySelector('body').appendChild(modal);
			that.modal = modal;
		});
	}

	submitModal(form) {
		var that = this;
		var fset = form.querySelector('fieldset');
		if(fset.disabled == true) {
			return;
		}
		else {
			fset.disabled = true;
		}
		var url = form.action;
		var formData = new FormData();
		formData.append('action', 'phunk_cookie_plugin_do_ajax_request');
		formData.append('action2', 'confirm_modal');
		formData.append('action', 'phunk_cookie_plugin_do_ajax_request');
		
		var cookie_settings = {
			confirmed: 1
		};

		form.querySelectorAll('input.phunk-cookie-option').forEach( function(el, index) {
			cookie_settings[el.name] = el.checked ? 1 : 0;
		});

		formData.append("cookie_settings", JSON.stringify(cookie_settings));

		fetch( url, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			body: formData
		})
		.then(function(res) {
			that.closeModal();
			location.reload();
		});
	}

	closeModal() {
		var that = this;
		that.modal.remove();
	}

	loadYoutube(el) {
		var fData = new FormData();
		var ytid = null;
		var iframe = null;
		if(el instanceof HTMLIFrameElement) {
			iframe = el;
		}
		else {
			iframe = el.querySelector('iframe');
		}
		
		let vid = null;
		if(iframe.dataset.ytid !== undefined) {
			vid = iframe.dataset.ytid;
			fData.append('action', 'AjaxGetYoutubeTemplate');
		}
		else {
			vid = iframe.dataset.vimeoid;
			fData.append('action', 'AjaxGetVimeoTemplate');
		}
		fData.append('vid', vid);

		fetch(phunk_cookie_plugin_ajax.ajax_url, {
			method: "POST",
			credentials: 'same-origin',
			body: fData
		})
		.then((response) => response.text())
		.then((html) => {
			if(html) {
				var parser = new DOMParser();
				var doc = parser.parseFromString(html, 'text/html');
				el.replaceWith(doc.querySelector('body').firstChild);
			}
			else {
				console.error("HTML Reponse empty");
			}
			
		})
		.catch((error) => {
			console.error(error);
		});
	}

	bindYoutubeSingleAction(el) {
		console.log(el);
		let btn = el.querySelector('.phunk-cookie-toggle-video');
		let that = this;
		if(btn) {
			btn.addEventListener('click', function(evt) {
				evt.preventDefault();
				/*
				let container = evt.target.closest('.phunk-cookie-youtube');
				that.toggleYoutube(container);*/
				that.loadYoutube(el);
			});
		}

	}
}

function phunkCookieLoaded(phunkCookie, cb) {
	cb.call(this, phunkCookie, cb);
}

function getPhunkCookiePluginSettings() {
	var nameEQ = phunk_cookie_plugin_ajax.phunkCookieName;
	var ca = document.cookie.split(';');
	for(var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') {
			c = c.substring(1,c.length);
		}
		if (c.indexOf(nameEQ) == 0) {
			var str = c.substring(nameEQ.length + 1,c.length);
			return JSON.parse(unescape(str));

		}
	}
	return null;
}

/*
phunkCookieReady( function() {
	(function bindCookieLinks() {
		document.querySelectorAll('a.phunk-cookie-link').forEach( function(el, index) {
			el.addEventListener("click", function(event) {
				event.preventDefault();
				PhunkCookie.loadModal(event.target.href);
			});
		});
	})();
});*/